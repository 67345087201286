var $ = require( "jquery" );
var jQuery = $;
const plg = require("../lib/functions-comunes");
const bootstrap = require('bootstrap');

export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('init common');
    $('.only-letters').on('keypress', function(event) {
      /* Act on the event */
      return OnlyLetters(event);
    });
    $('.only-numbers').on('keypress', function(event) {
      /* Act on the event */
      return onlyNumbers(event);
    });
    $('.date-format').on('keypress', function(event) {
      /* Act on the event */
      return dateFormat(event);
    });
    $('.js-uppercase').on('keypress', function(){
      //console.log('paso');
      valor = $(this).val().toUpperCase();
    //   console.log(valor);
      $(this).val(valor); 
    });
  
   //ANTI-PASTE
    $('.anti-paste').bind("cut copy paste",function(e) {
        e.preventDefault();
    });

    /**
     * LISTENER close error alert
     */
    $('.js-close-alert').on('click', function(){
      $(this).closest('.alert').slideUp('slow');
    });

    /**
     * LISTENER close modal
     */
    $('.close').on('click', function(){
      //$(this).closest('.modal').hide();
      var _modal = $(this).closest('.modal').attr('id');
      var myModal2 = new bootstrap.Modal(document.getElementById(_modal), {});  
      myModal2.hide();

    });
     
    $('.ico-info').on('click', function(){
      $(this).find(".tip-text").slideToggle();
    });

     $(window).scroll(function(event) {
       var valorScroll=$(window).scrollTop();
       if (valorScroll > 100){
         $('header').addClass('small');
       }else{
         $('header').removeClass('small');
       }
     });

    /*var titleTop = $('.cont-title').offset().top-$("header").height();
    $(window).scroll(function(event) {
      var valorScroll=$(window).scrollTop();
      if (valorScroll > titleTop){
        $('header').addClass('small');
      }else{
        $('header').removeClass('small');
      }
    });*/

    $('.js-eye').on('click', function(){
      var _el = $(this).attr('data-idshow');
      if ($(this).hasClass('pass_hidden')){
        $('#'+_el).attr('type', 'input');
        $(this).removeClass('pass_hidden');
        $(this).addClass('pass_visible');
        $(this).find('img').attr('src', 'images/ico-ver.svg');
      }else{
        $('#'+_el).attr('type', 'password');
        $(this).addClass('pass_hidden');
        $(this).removeClass('pass_visible');
        $(this).find('img').attr('src', 'images/ico-ver-on.svg');
      }
  });

    $('.js-logout').on('click', function(){
      $.ajax({
        url: "ajax/ajax-logout.php",
        type: "POST",
        dataType: "json",
        success: function (response) {
          document.location.href = "/";
        },
        error: function (xhr, status) {
        },
        complete: function (xhr, status) {
        }        
      });
  });

  },
  callback_ajax(funcion,response){
    eval("this.callback_"+funcion+"("+response+")");
  },
  callback_sectionuser(res){
    var _resultcb = res;
    if (_resultcb['result'] == 'OK'){
      var objName = $('.user-info');
      var _elem = $(objName);
      if (_resultcb['txt0'] != ''){
        _elem.find('h1').html(_resultcb['txt0'])+' '+_resultcb['name'];
      }else{
        _elem.find('h1').html(_resultcb['name']);
      }
      _elem.find('p').html(_resultcb['txtredemptions']);  
  
      objName.removeClass("loadajax-item");
      objName.css('opacity',0);
      objName.delay(50).animate({opacity: 1},400);
    }  
  },
  callback_footermenu(res){
    var _resultcb = res;
    var _lastitem;
    if (_resultcb['result'] == 'OK'){
      var objName='.footermenu.loadajax-item';
      $(objName).not(':first').remove(); //remove all but firstone to execute listener&loop only in one dom
      var _elem = $(objName);
      jQuery.each( _resultcb['data'], function( i, val ) {
        if (val.pdf !== null){
          var tmpobj=_elem.clone(true).appendTo(_elem.parent());
          
          tmpobj.find('a').attr("href",val.pdf);
          tmpobj.find('a').html(val.label);
          
          

          tmpobj.removeClass("loadajax-item");
          tmpobj.css('opacity',0);
          tmpobj.delay(i*50).animate({opacity: 1},400);
          _lastitem = tmpobj;
        } 
      });

      $(objName).remove();
    }  

    if (_resultcb['data']['privacy'].pdf != null && _resultcb['data']['privacy'].pdf != '' && _resultcb['data']['privacy'].pdf != 'null' ){
      $('.js-docs-policy').attr('href',_resultcb['data']['privacy'].pdf);
    }else{
      $('.js-docs-policy').attr('href',_resultcb['data']['privacy_cookies'].pdf);
    }  
    $('.js-docs-terms').attr('href',_resultcb['data']['terms_conditions'].pdf);
    if ($('#itProvincy').length > 0){
      jQuery.each( _resultcb['counties'], function( i, val ) {
        $('#itProvincy').append("<option value='"+val.name+"' >"+val.name+"</option>");
      });
    }  
    if ($('.js-email-contact').length > 0){
      $('.js-email-contact').attr('href', 'mailto:'+_resultcb['contact'].email);
      $('.js-email-contact').html(_resultcb['contact'].email);
    }  
    if ($('.js-phone-contact').length > 0){
      $('.js-phone-contact').attr('href', 'tel:'+_resultcb['contact'].phone);
      $('.js-phone-contact').html(_resultcb['contact'].phone);
    } 

    $('#footer_contact').appendTo(_lastitem.parent());
    $('#footer_contact').css('opacity', 1);
    $('#footer_team').appendTo($('#footer_contact'));
    $('#footer_team').css('opacity', 1);
    /**
     * Show errors
     */
    var error = $('main').data('erroronload');
    if(error != '' && error != null){
      plg.PLG_showModalAlerta('Error', error);
    }

  },  
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};

/**
   * AUXILIARS FUNCTIONS
   * 
*/
function OnlyLetters(e){
  var key = e.keyCode || e.which;
  var _key = String.fromCharCode(key).toLowerCase();
  var letters = " áéíóúabcdefghijklmnñopqrstuvwxyz";
  var specials = "8-37-39-46";

  var key_special = false
  for(var i in specials){
       if(key == specials[i]){
         key_special = true;
           break;
       }
   }

   if(letters.indexOf(_key)==-1 && !key_special){
       return false;
   }
}
function onlyNumbers(e){
  var key = e.keyCode || e.which;
  var _key = String.fromCharCode(key).toLowerCase();
  var numbers = "0123456789";
  var specials = "8-37-39-46";

  var key_special = false
  for(var i in specials){
       if(key == specials[i]){
         key_special = true;
           break;
       }
   }

   if(numbers.indexOf(_key)==-1 && !key_special){
       return false;
   }
}

function dateFormat(e){
  var key = e.keyCode || e.which;
  var _key = String.fromCharCode(key).toLowerCase();
  var chars = "/0123456789";
  var specials = "8-37-39-46";

  var key_special = false
  for(var i in specials){
       if(key == specials[i]){
         key_special = true;
           break;
       }
   }

   if(chars.indexOf(_key)==-1 && !key_special){
       return false;
   }

}
