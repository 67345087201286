import Swiper, { Navigation,Autoplay } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
Swiper.use([Navigation,Autoplay]);
import * as bootstrap from 'bootstrap';
var $ = require( "jquery" );
var jQuery = $;
const plg = require("../lib/functions-comunes");
var myModal;
var myModalError;
export default {
  init() {
    console.log("init home");
    myModalError = new bootstrap.Modal(document.getElementById('modal-error'), {}); 

    const registrateButton = document.querySelector('.js-registrate');
    if (registrateButton) {  
      const profileTab = document.querySelector('#profile-tab');
  
      registrateButton.addEventListener('click', function(e) {
        e.preventDefault();
        profileTab.classList.add('active');
        const homeTab = document.querySelector('#home-tab');
        homeTab.classList.remove('active');
        const profileContent = document.querySelector('#profile');
        profileContent.classList.add('active', 'show');
        const homeContent = document.querySelector('#home');
        homeContent.classList.remove('active', 'show');
      });
    }

    $('#profile-tab').on('click', function(){
      $('html').css('height', 'auto');
      $('body').css('height', 'auto');
    });

    $('#home-tab').on('click', function(){
      $('html').css('height', '');
      $('body').css('height', '');
    });

    //validate code form    
    $('#js-btlogin').on('click', function() {
      
      var error = false;
      var form = document.getElementById('formLogin');
      var formJS = $('#formLogin');
      //formJS.find('.alert .items-invalid').html("");
      var _errors= '';
      var email = document.getElementById('email');
      var password = document.getElementById('password');
      if (form.checkValidity() === false) {
        error = true;
        if (!email.validity.valid) _errors += ("<li>" + $('#email').closest('.form-group').data('error-message') + "</li>");
        if (!password.validity.valid) _errors += ("<li>" + $('#password').closest('.form-group').data('error-message') + "</li>");
        console.log(_errors);
      }
      formJS.addClass('was-validated');
      if (!error) {
        $('#js-btlogin').addClass('loader');

        formJS.find('.alert').slideUp("fast");
        var datos = formJS.serialize();
        $.ajax({
            method: "POST",
            url: "ajax/ajax-login.php",
            data: datos
          })
          .done(function(resultAjax) {
          formJS.removeClass('was-validated');
          resultLogin(resultAjax);
          $('#js-btlogin').removeClass('loader');
        });
      } else {
        $('#modal-error .modal-body').find('.errors').html(_errors); 
        $('#modal-error .modal-body').find('.txterrors').html(formJS.data('txterror'));
        myModalError.show();
      }

    });

    //validate register form    
    $('#js-btregister').on('click', function() {
      
      var error = false;
      var form = document.getElementById('formRegister');
      var formJS = $('#formRegister');
      //formJS.find('.alert .items-invalid').html("");
      var _errors= '';
      var code_redemption = document.getElementById('code_redemption');
      var name = document.getElementById('itName');
      var lastname = document.getElementById('itLastName');
      var email = document.getElementById('itEmail');
      var confEmail = document.getElementById('confEmail');
      var password = document.getElementById('itPassword');
      var password2 = document.getElementById('itPassword2');
      
      
      
      var checkLegal1 = document.getElementById('check-legal1');

          
      if (form.checkValidity() === false || $('#itEmail').val() != $('#confEmail').val() || $('#itPassword').val() != $('#itPassword2').val()) {
        error = true;
        if (!name.validity.valid) _errors += ("<li>" + $('#itName').closest('.form-group').data('error-message') + "</li>");
        if (!lastname.validity.valid) _errors += ("<li>" + $('#itLastName').closest('.form-group').data('error-message') + "</li>");
        if (!email.validity.valid) _errors += ("<li>" + $('#itEmail').closest('.form-group').data('error-message') + "</li>");
        if ($('#itEmail').val() != $('#confEmail').val()){
           _errors += ("<li>" + $('#confEmail').closest('.form-group').data('error-message') + "</li>");
        }
        if (!password.validity.valid) _errors += ("<li>" + $('#itPassword').closest('.form-group').data('error-message') + "</li>");
        if ($('#itPassword').val() != $('#itPassword2').val()){
          _errors += ("<li>" + $('#itPassword2').closest('.form-group').data('error-message') + "</li>");
       }
        if (!checkLegal1.validity.valid) _errors += ("<li>" + $('#check-legal1').closest('.form-group').data('error-message') + "</li>");
        
      }

      formJS.addClass('was-validated');
      if (!error) {
        $('#js-btregister').addClass('loader');

        //formJS.find('.alert').slideUp("fast");
        var datos = formJS.serialize();
        $.ajax({
            method: "POST",
            url: "ajax/ajax-register.php",
            data: datos
          })
          .done(function(resultAjax) {
          formJS.removeClass('was-validated');
          resultRegister(resultAjax);
          $('#js-btregister').removeClass('loader');
        });
      } else {
        /*formJS.find('.alert').slideDown(function() {});
        plg.PLG_scrollTo(1, "#section-home", 0, 100);*/
        $('#modal-error .modal-body').find('.errors').html(_errors); 
        $('#modal-error .modal-body').find('.txterrors').html(formJS.data('txterror'));
        myModalError.show();
      }

    });    

    
    $('.js-addcode').on('click', function() {
      var _bt = $(this).attr('id');
      var error = false;
      var form = document.getElementById('formCode');
      var formJS = $('#formCode');
      //formJS.find('.alert .items-invalid').html("");
      var _errors= '';
      var code = document.getElementById('code');
      if (form.checkValidity() === false) {
        error = true;
        if (!code.validity.valid) _errors += ("<li>" + $('#code').closest('.form-group').data('error-message') + "</li>");
      }
      formJS.addClass('was-validated');
      if (!error) {
        $('#'+_bt).addClass('loader');

        formJS.find('.alert').slideUp("fast");
        var datos = formJS.serialize();
        $.ajax({
            method: "POST",
            url: "ajax/ajax-check-code.php",
            data: datos
          })
          .done(function(resultAjax) {
          formJS.removeClass('was-validated');
          resultCode(resultAjax, _bt); 
          $('#'+_bt).removeClass('loader');
        });
      } else {
        $('#modal-error .modal-body').find('.errors').html(_errors); 
        $('#modal-error .modal-body').find('.txterrors').html(formJS.data('txterror'));
        myModalError.show();
      }

    });
    
    
  },
  callback_ajax(funcion,response){
    eval("this.callback_"+funcion+"("+response+")");
  },
  callback_networklogos(res){
    var _resultcb = res;
    if (_resultcb['result'] == 'OK'){
      var objName='.network.loadajax-item';
      $(objName).not(':first').remove(); //remove all but firstone to execute listener&loop only in one dom
      var _elem = $(objName);
      jQuery.each( _resultcb['data'], function( i, val ) {
        var tmpobj=_elem.clone(true).appendTo(_elem.parent());
        tmpobj.attr("src",val.logo);
        tmpobj.attr("alt",val.network);
  
        tmpobj.removeClass("loadajax-item");
        tmpobj.css('opacity',0);
        tmpobj.delay(i*50).animate({opacity: 1},400);
      });
      $(objName).remove();
    }  
  },
  callback_partners(res){
    var _resultcb = res;
    if (_resultcb['result'] == 'OK'){
      var objName='.swiper-partner .loadajax-item';
      $(objName).not(':first').remove(); //remove all but firstone to execute listener&loop only in one dom
      var _elem = $(objName);
      jQuery.each( _resultcb['data'], function( i, val ) {
        var tmpobj=_elem.clone(true).appendTo(_elem.parent());
        tmpobj.find(".bgimage").css("background-image","url('"+val.square_image+"')");
        tmpobj.find("a").attr("href","/partner/"+val.slug);
        tmpobj.find(".titulo").html(val.name);
  
        tmpobj.removeClass("loadajax-item");
        tmpobj.css('opacity',0);
        tmpobj.delay(i*50).animate({opacity: 1},400);
      });
      $(objName).remove();
    }  
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};

function resultLogin(_result){
  var result = JSON.parse(_result);
  if (result['result'] == 'OK' ){      
      //MIXPANEL
      if (typeof mixpanel != "undefined") {
        mixpanel.track("Login finished", {
        });
      }
      document.location.href = result['redirect'];
  }else{
      $('#js-btlogin').removeClass("loader");

      $('#modal-error .modal-body').find('.errors').hide(); 
      $('#modal-error .modal-body').find('.txterrors').html(result['error']);
      $('#modal-error .modal-button').find('.txterrors').html('<a href="javascript:;" class="js-close">'+result['boton']+"</a>");
      myModalError.show();
  }  
}

function resultRegister(_result){
  var result = JSON.parse(_result);
  if (result['result'] == 'OK' ){


    //MIXPANEL
    if (typeof mixpanel != "undefined") {
      mixpanel.track("Register finished", {
        "Age": $("#itAge").val(),
        "Gender": $("#itGender").val(),
        "TLC opt in": $('#check-legal3').is(':checked')
      });
    }  



    document.location.href = 'welcome';
  }else{
    $('#js-btlogin').removeClass("loader");

    $('#modal-error .modal-body').find('.errors').hide(); 
    $('#modal-error .modal-body').find('.txterrors').html(result['error']);
    $('#modal-error .modal-button').find('.txterrors').html('<a href="javascript:;" class="js-close">'+result['boton']+"</a>");
    $('#modal-error .modal-button').show();
    myModalError.show();
  }
}  

function resultCode(_result, _bt){
  var result = JSON.parse(_result);

  if (result['result'] == 'OK' ){

    //MIXPANEL
    if (typeof mixpanel != "undefined") {
      mixpanel.track("Code entered", {
        "Client Name": result['client_name'],
        "Promotion ID": result['id_promocion']
      });
    }


    if (_bt == 'js-btcode2'){
      $('#addcredits').html(result['credits']);
      $('.js-first').hide();
      $('.js-other').removeClass('d-none');
      $('#js-redirect').attr('href', result['redirect']);
      $('#code').val('');  
    }else{
      document.location.href = result['redirect'];
    }  
  }else{
    $('.js-addcode').removeClass("loader");

    $('#modal-error .modal-body').find('.errors').hide(); 
    $('#modal-error .modal-body').find('.txterrors').html(result['error']);
    myModalError.show();
  }
}
