var $ = require( "jquery" );
var jQuery = $;
import "./lib/functions-comunes.js";
import "./lib/functions-initlisteners.js";
import "./lib/functions-forms.js";


import Router from './util/Router';
import common from './routes/common';
import home from './routes/home';
import categories from './routes/categories';
import subcategories from './routes/subcategories';
import offers from './routes/offers';
import contact from './routes/contact';
import partner from './routes/partner';
import remember from './routes/remember';
import area from './routes/area';


/** Populate Router instance with DOM routes */
const routes = new Router({
  common,
  home,
  categories,
  subcategories,
  offers,
  contact,
  partner,
  remember,
  area
});

$(document).ready(() => routes.loadEvents());
window.app=routes;


