var $ = require( "jquery" );
var jQuery = $;
const plg = require("../lib/functions-comunes");
const bootstrap = require('bootstrap');
var myModal;
var myModalError;
export default {
  init() {
    // JavaScript to be fired on all pages
    console.log('init private');
    myModalError = new bootstrap.Modal(document.getElementById('modal-error'), {}); 

    $('.js-editName').on('click', function(){
      if ($('#nameupd').prop('readonly')){//si es readonly --> dejar modificar
        $('#nameupd').prop('readonly', false);
        $('#icoedit').attr('src', '/images/ico-guardar.svg');
        $('#icoedit2').attr('src', '/images/ico-noeditar.svg');
        $('#icoeditpass').attr('src', '/images/ico-noeditar.svg');
        $('.js-updpassword').addClass('no-cursor');

      }else{
        if ($('#nameupd').val() != ''){
          
          $.ajax({
            url: "ajax/ajax-update-name.php",
            data: "&nameupd="+$('#nameupd').val(),
            type: "POST",
            dataType: "json",
            success: function (response) {
              var obj, target, spinner_holder;
              $('#nameupd').prop('readonly', true);
              $('#icoedit').attr('src', '/images/ico-editar.svg');
              $('#icoedit2').attr('src', '/images/ico-editar.svg');
              $('#icoeditpass').attr('src', '/images/ico-editar.svg');
              $('.js-updpassword').removeClass('no-cursor');
            },
            error: function (xhr, status) {
              plg.PLG_showModalAlerta($('#txtups').val(), $('#txtgeneric_error').val());
            },
            complete: function (xhr, status) {
            }

          });
        }else{
          var _err = $('#nameupd').closest('.form-group').attr('data-error-message');
          $('#modal-error .modal-body').find('.errors').hide(); 
          $('#modal-error .modal-body').find('.txterrors').html(_err);
          myModalError.show();
        }  
        
      }  
      
    });

    $('.js-editLastName').on('click', function(){
      if ($('#lastnameupd').prop('readonly')){//si es readonly --> dejar modificar
        $('#lastnameupd').prop('readonly', false);
        $('#icoedit2').attr('src', '/images/ico-guardar.svg');
        $('#icoedit').attr('src', '/images/ico-noeditar.svg');
        $('#icoeditpass').attr('src', '/images/ico-noeditar.svg');
        $('.js-updpassword').addClass('no-cursor');

      }else{
        if ($('#lastnameupd').val() != ''){
          
          $.ajax({
            url: "ajax/ajax-update-lastname.php",
            data: "&lastnameupd="+$('#lastnameupd').val(),
            type: "POST",
            dataType: "json",
            success: function (response) {
              var obj, target, spinner_holder;
              $('#lastnameupd').prop('readonly', true);
              $('#icoedit2').attr('src', '/images/ico-editar.svg');
              $('#icoedit').attr('src', '/images/ico-editar.svg');
              $('#icoeditpass').attr('src', '/images/ico-editar.svg');
              $('.js-updpassword').removeClass('no-cursor');
            },
            error: function (xhr, status) {
              plg.PLG_showModalAlerta($('#txtups').val(), $('#txtgeneric_error').val());
            },
            complete: function (xhr, status) {
            }

          });
        }else{
          var _err = $('#lastnameupd').closest('.form-group').attr('data-error-message');
          $('#modal-error .modal-body').find('.errors').hide(); 
          $('#modal-error .modal-body').find('.txterrors').html(_err);
          myModalError.show();
        }  
        
      }  
      
    });

    $('#js-btcode-private').on('click', function() {
        var error = false;
        var form = document.getElementById('formCodePrivate');
        var formJS = $('#formCodePrivate');
        //formJS.find('.alert .items-invalid').html("");
        var _errors= '';
        var code = document.getElementById('code');
        if (form.checkValidity() === false) {
          error = true;
          if (!code.validity.valid) _errors += ("<li>" + $('#code').closest('.form-group').data('error-message') + "</li>");
        }
        formJS.addClass('was-validated');
        if (!error) {
          $('#js-btcode-private').addClass('loader');
  
          var datos = formJS.serialize();
          $.ajax({
              method: "POST",
              url: "ajax/ajax-check-code-private.php",
              data: datos
            })
            .done(function(resultAjax) {
            formJS.removeClass('was-validated');
            resultCodePrivate(resultAjax);
            $('#js-btcode-private').removeClass('loader');
          });
        } else {
          $('#modal-error .modal-body').find('.errors').html(_errors); 
          $('#modal-error .modal-body').find('.txterrors').html(formJS.data('txterror'));
          myModalError.show();
        }
  
      });

      $('.js-campaign').on('click', function(){
        var _idcampaign = $(this).attr('data-idcampaign');
        $.ajax({
          url: "ajax/ajax-select-campaign.php",
          data: "&promotion_id="+_idcampaign+"&credits="+$(this).attr('data-credits'),
          type: "POST",
          dataType: "json",
          success: function (response) {
              document.location.href="categories";            
          },
          error: function (xhr, status) {
            plg.PLG_showModalAlerta($('#txtups').val(), $('#txtgeneric_error').val());
          },
          complete: function (xhr, status) {
          }

        });

      });

      $('#js-btreset').on('click', function() {
        var error = false;
        var form = document.getElementById('formReset');
        var formJS = $('#formReset');
        //formJS.find('.alert .items-invalid').html("");
        var _errors= '';
        var password = document.getElementById('itPassword');
        var password2 = document.getElementById('itPassword2');
        if (form.checkValidity() === false || $('#itPassword').val() != $('#itPassword2').val()) {
            error = true;
            if (!password.validity.valid) _errors += ("<li>" + $('#itPassword').closest('.form-group').data('error-message') + "</li>");
            if ($('#itPassword').val() != $('#itPassword2').val()){
              _errors += ("<li>" + $('#itPassword2').closest('.form-group').data('error-message') + "</li>");
           }
        }
        formJS.addClass('was-validated');
        if (!error) {
            
          $('#js-btreset').addClass('loader');
  
          formJS.find('.alert').slideUp("fast");
          var datos = formJS.serialize();
          $.ajax({
              method: "POST",
              url: "ajax/ajax-reset-private.php",
              data: datos
            })
            .done(function(resultAjax) {
            formJS.removeClass('was-validated');
            resultReset(resultAjax);
            $('#js-btreset').removeClass('loader');
          });
        } else {
          $('#modal-error .modal-body').find('.errors').html(_errors); 
          $('#modal-error .modal-body').find('.txterrors').html(formJS.data('txterror'));
          myModalError.show();
        }
  
      });
     
     
  },
  callback_ajax(funcion,response){
    eval("this.callback_"+funcion+"("+response+")");
  },
  callback_sectioncampaigns(res){
    var _resultcb = res;
    if (_resultcb['result'] == 'OK' ){
      var objName='#section_campaigns .loadajax-item';
      $(objName).not(':first').remove(); //remove all but firstone to execute listener&loop only in one dom
      var _elem = $(objName);
      jQuery.each( _resultcb['data'], function( i, val ) {
        var tmpobj=_elem.clone(true).appendTo(_elem.parent());      
        
        tmpobj.find(".title").html(val.title);
        tmpobj.find(".credits").html(val.credits);
        tmpobj.find(".expire_min").html(val.expire_min);
        tmpobj.attr('data-idcampaign', val.id);
        tmpobj.attr('data-credits', val.credits);
        jQuery.each(val.categories, function (j, val2) {
          console.log(val2);
          // tmpobj.find(".networks").append('<div class="category"><img src="'+val2.image_network+'"><div class="d-none hover">'+val2.description+'</div></div>');
          tmpobj.find(".networks").append('<div class="category"><img src="'+val2.image_network+'"><a class="ico-desplegable" href="javascript:;"><img src="/images/ico-desplegable.svg"><div class="d-none hover">'+val2.description+'</div></a></div>');

        });
        tmpobj.removeClass("loadajax-item");
        tmpobj.css('opacity',0);
        tmpobj.find('.card-info').css('opacity',1);
        tmpobj.delay(i*50).animate({opacity: 1},400);
        
      });
      
      $(objName).remove();
    }  
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  },
};



function resultCodePrivate(_result){
    var result = JSON.parse(_result);
    if (result['result'] == 'OK' ){
      //MIXPANEL
      if (typeof mixpanel != "undefined") {
        mixpanel.track("Code entered", {
          "Client Name": result['client_name'],
          "Promotion ID": result['id_promocion']
        });
      }
      plg.PLG_showModalAlerta(result['text1'], result['text2']);
    }else{
        $('#js-btcode-private').removeClass("loader");

        $('#modal-error .modal-body').find('.errors').hide(); 
        $('#modal-error .modal-body').find('.txterrors').html(result['error']);
//        $('#modal-error .modal-button').find('.txterrors').html('<a href="javascript:;" class="js-close">'+result['boton']+"</a>");
//        $('#modal-error .modal-button').show();
        myModalError.show();
    }
}

function resultReset(_result){
  var result = JSON.parse(_result);
  if (result['result'] == 'OK' ){
      document.location.href = 'change-password-end';
  }else{
      $('#js-btreset').removeClass("loader");

      $('#modal-error .modal-body').find('.errors').hide(); 
      $('#modal-error .modal-body').find('.txterrors').html(result['error']);
      $('#modal-error .modal-button').find('.txterrors').html('<a href="javascript:;" class="js-close">'+result['boton']+"</a>");
      $('#modal-error .modal-button').show();
      myModalError.show();
  }
}