var $ = require( "jquery" );
var jQuery = $;
require('jquery-ui/ui/widget');
require('jquery-ui/ui/keycode');
require('jquery-ui/ui/widgets/mouse');
require('jquery-ui/ui/widgets/slider');
require('jquery-ui/themes/base/core.css');
require('jquery-ui/themes/base/slider.css');
const plg = require("../lib/functions-comunes");
var map;

export default {
  init() {
    // JavaScript to be fired on the home page
    console.log('init subcategories');
    
    setTimeout(function(){ 
      initMap();
      $( "#slider" ).slider({
        max: $('#radius_max').val(),
        min: $('#radius_min').val(),
        value: $('#radius_default').val(),
        step: 5	
      });

        function initSliderStart(){
          if (!$('#slider .slider-start').length){
            $("#slider").append("<div class='slider-start'></div>")
          }
          if ($('#slider').length){
            setTimeout(function(){
              $('.slider-start').width($('#slider .ui-slider-handle')[0].style.left);
            },25)
          }
        }
        initSliderStart();
        $("#slider").on("slidestop", function(event, ui) {
          searchRadius();
        });
      
        $( "#slider" ).on( "slide", function( event, ui ) {
            var endPos = ui.value;
            $('.label-slider').html(endPos+' '+$('#unit').val());
            $('#radius_selected').val(endPos);
            initSliderStart();
          } );
        
    }, 500);

    $('.js-filter').on('click', function(){
      var _filter = $(this).attr('data-value');
      var _type = '';
      if ($(this).hasClass('js-inout')){
        _type = 'inout';
      }else if ($(this).hasClass('js-categories')){
        _type = 'categories';
      }else if ($(this).hasClass('js-subcategories')){
        _type = 'subcategories';
      }else if ($(this).hasClass('js-provinces')){
        _type = 'provinces';
      }


      if($('#ckinout_1').prop('checked') && !$('#ckinout_2').prop('checked')){
        $('.js-categories[data-in=0]').prop('checked', false);
      } 

      if($('#ckinout_2').prop('checked') && !$('#ckinout_1').prop('checked')){
        $('.js-categories[data-out=0]').prop('checked', false);
      }

      

      $('.js-categories').each(function(index){
        if (!$(this).prop('checked')){
            $('.js-subcategories[data-category='+$(this).attr('data-value')+']').closest('div').hide();
            $('.js-subcategories[data-category='+$(this).attr('data-value')+']').prop('checked', false);
        }else{
          //si se selecciona una categoria, se seleccionan todas las subcategorias
          if (_type == 'categories' && _filter == $(this).attr('data-value')){
            $('.js-subcategories[data-category='+$(this).attr('data-value')+']').closest('div').show();
            $('.js-subcategories[data-category='+$(this).attr('data-value')+']').prop('checked', true);
          }  
        }            
      });  
      

      
            
      if (_type != 'provinces'){
        $('.js-provinces').each(function(index){
          var _prov = $(this);
          var _checked = false;

          $(".js-subcategories").each(function( index ) { 
            if ($(this).prop('checked')){
              var _regions = $(this).attr('data-regions').split(',');
              //console.log($(this).attr('data-value')+'>>'+_regions+'>>'+val.province_parse);
              if (jQuery.inArray( _prov.attr('data-value'), _regions ) >= 0){
                _checked = true;
                //console.log('siii'+val.province_parse);
              }  
            }  
          });  
          
          if (_checked){
            _prov.prop( "checked", true );
          }else{
            _prov.prop( "checked", false );
          }
        });  
      }
    


      apply_filters();

      

    });

    $('.js-select').on('click', function(){
      
        //preparar filtros por si desde offers da a volver
        var _categories = '';
        $( ".js-categories" ).each(function( index ) {
          if( $(this).prop('checked') ) {
            _categories += $(this).attr('data-value')+','
          }
        });

        var _subcategories = '';
        $( ".js-subcategories" ).each(function( index ) {
          if( $(this).prop('checked') ) {
            _subcategories += $(this).attr('data-value')+','
          }
        });

        var _provinces = '';
        $( ".js-provinces" ).each(function( index ) {
          //si la provincia está seleccionada y es visible 
          if( $(this).prop('checked') ) {
            _provinces += $(this).attr('data-value')+',';
          }
        });

       // plg.PLG_setCookie("tlcpromo_categories", _categories, 365);
        //plg.PLG_setCookie("tlcpromo_subcategories", _subcategories, 365);
        plg.PLG_setCookie("tlcpromo_provinces_selected", _provinces, 365);


        if ($('.js-inout[data-value=1]').prop('checked')){
          plg.PLG_setCookie("tlcpromo_in_selected", 1, 365);
          plg.PLG_setCookie("tlcpromo_in", 1, 365);
        }
        else{
          plg.PLG_setCookie("tlcpromo_in_selected", 0, 365);
          plg.PLG_setCookie("tlcpromo_in", 0, 365);
        }

        if ($('.js-inout[data-value=2]').prop('checked')){
          plg.PLG_setCookie("tlcpromo_out_selected", 1, 365);
          plg.PLG_setCookie("tlcpromo_out", 1, 365);          
        }
        else{
          plg.PLG_setCookie("tlcpromo_out_selected", 0, 365);
          plg.PLG_setCookie("tlcpromo_out", 0, 365);
        }

        //ir al listado de offers correspondiente
        var _sub = $(this).closest('.js-subcategory').attr('data-subcategory');
        plg.PLG_setCookie("tlcsubcategory_selected", _sub, 365);
        plg.PLG_setCookie("tlcpromo_categories", _categories, 365);
         

        document.location.href="offers";
       
    });

    $(window).scroll(function(event) {
      var valorScroll=$(window).scrollTop();
      if (valorScroll > $("#sectionuser").height()+34){
        $('#list').addClass('filters-fixed');
      }else{
        $('#list').removeClass('filters-fixed');
      }
    });

  },
  callback_ajax(funcion,response){
    eval("this.callback_"+funcion+"("+response+")");
  },
  callback_filters(res){
    var _resultcb = res;
    if (_resultcb['result'] == 'OK'){
      //console.log(_resultcb['data_inout']);
      callback_filters_inout(_resultcb['data_inout']);
      callback_filters_categories(_resultcb['data_categories']);
      callback_filters_subcategories(_resultcb['data_subcategories']);
      callback_filters_provinces(_resultcb['data_provinces']);
      callback_filters_sectionsubcategories(_resultcb['data_subcategories']);
      apply_filters();
      if ($('.filter-search').length > 0){
        $('.filter-search').fadeIn();
      }
    }  
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
    
  },
};

function apply_filters() {
  if ($('.filter-search').length > 0 && $('#pac-input').val() != ''){
    searchRadius();
  }else{
    var _ckinout = [];//$('input[name=ck_inout]').val();
    var _ckcategories = [];//$('input[name=ck_categories]').val();
    var _cksubcategories = [];//$('input[name=ck_subcategories]').val();
    var _ckregions = [];//$('input[name=ck_regions]').val();
    $('#txt-results span').html('');
    $('#txt-results').hide();

    $( ".js-inout" ).each(function( index ) {
      if ($(this).prop('checked')){
        _ckinout.push(parseInt($(this).attr('data-value')));
      }
    });

    $( ".js-categories" ).each(function( index ) {
      if ($(this).prop('checked')){
        _ckcategories.push($(this).attr('data-value'));
      }
    });

    $( ".js-subcategories" ).each(function( index ) {
      if ($(this).prop('checked')){
        _cksubcategories.push($(this).attr('data-value'));
      }
    });

    
    var _show = false;
    if (_ckinout.length > 0){
      $('.js-categories').closest('div').hide();
      
      if (jQuery.inArray( 1, _ckinout ) >= 0){ 
          $('.js-categories[data-in=1]').closest('div').show();
      } 

      if (jQuery.inArray( 2, _ckinout ) >= 0){ 
        $('.js-categories[data-out=1]').closest('div').show();
      } 
    }else{
      $('.js-categories').show();
    }  

    
    $(".js-categories").each(function( index ) {  
      var _cat = $(this).attr('id').split('_')[1];

      $('.js-subcategories[data-category='+_cat+']').closest('div').hide();

      if (jQuery.inArray( _cat, _ckcategories ) >= 0){
        // console.log('esta:: '+_cat);
        $('.js-subcategories[data-category='+_cat+']').each(function(index2){
          if (_ckinout.length > 0){
            if (jQuery.inArray( 1, _ckinout ) >= 0  && $(this).attr('data-in') == "1"){ 
              $('.js-subcategories[data-category='+_cat+']').closest('div').show();
              // console.log('muestra1 subcat '+_cat);
            }
            if (jQuery.inArray( 2, _ckinout ) >= 0  && $(this).attr('data-out') == "1"){ 
              $('.js-subcategories[data-category='+_cat+']').closest('div').show();
              // console.log('muestra2 subcat '+_cat);
            }
          }else{
            console.log('muestra3 subcat '+_cat);
            $('.js-subcategories[data-category="'+_cat+'"]').closest('div').show();
          }  
        });
        //console.log('check cat '+_cat);
      }else{
        $('.js-subcategories[data-category='+_cat+']').closest('div').hide();
        //console.log('no  subcat '+_cat);
      }
    });   
    
    $('.js-provinces').closest('div').hide();
    //$('.js-provinces').prop('checked', false);

    $(".js-subcategories").each(function( index ) { 
        var _regions = ''; 
        if (jQuery.inArray( $(this).attr('id').split('_')[1], _cksubcategories ) >= 0){
          _regions = $(this).attr('data-regions').split(',');
          //remove last comma string if exists
          if (_regions[_regions.length-1] == ','){
            _regions.pop();
          }
          console.log('regiones sub:'+$(this).attr('id')+' >> '+_regions);
          $.each(_regions, function(index, value) {
            if (value != ''){
              console.log("valor de value",value);
              if (value != '.'){
                $('.js-provinces[data-value='+value+']').closest('div').show();
                //$('.js-provinces[data-value='+value+']').prop('checked', true);
                _ckregions.push(value);
              }
            }
          });          
        }  
    });  

    /*console.log(_ckinout);
    console.log(_ckcategories);
    console.log(_cksubcategories);
    console.log(_ckregions);*/
   
    $('.js-subcategory').each(function( index ) { 
      var _show = true;

      if ((jQuery.inArray( 1, _ckinout ) >= 0 &&  jQuery.inArray( 2, _ckinout ) < 0 && $(this).attr('data-in') != '1') && (jQuery.inArray( 2, _ckinout ) >= 0 && jQuery.inArray( 1, _ckinout ) < 0 && $(this).attr('data-out') != '1')){
        _show = false;
        //console.log($(this).attr('data-subcategory')+' >> pasa0 ');
      }else{
        if (jQuery.inArray( $(this).attr('data-category'), _ckcategories ) < 0){
          _show = false;
          //console.log($(this).attr('data-subcategory')+' >> pasa2 ');
        }else{
          if (jQuery.inArray( $(this).attr('data-subcategory'), _cksubcategories ) < 0){
            _show = false;
            //console.log($(this).attr('data-subcategory')+' >> pasa3 ');
          }else{
            //console.log($(this).attr('data-regions'));
            
            var _regions_sub = $(this).attr('data-regions').split(',');
            var _b = false;
            $.each(_regions_sub, function(index, value) {
              if (_regions_sub == ''){
                _b = true;
              }
              else{
                if (jQuery.inArray( value, _ckregions ) >= 0 && $('.ckprovinces[data-value='+value+']').prop('checked')){
                  _b = true;    
                }
              }  
            });
            if (!_b){
              _show = false;
              //console.log($(this).attr('data-subcategory')+' >> pasa4 ');
              
            }  
          }
        }
      }  
      
      if (_show){
        $(this).show();
        //console.log('muesta:: '+$(this).attr('data-subcategory'));
      }else{
        $(this).hide();
       
      }
      //$(this).show();*/
    });  

  }

  //MIXPANEL
    var categoriesSelected=[];
    $('.filter-categories .js-filter').each(function( index ) {	
      if($(this).is(':checked')){
        categoriesSelected[index]=$(this).parent(".filtercategories").find("label").text();
      }
    });
    if (typeof mixpanel != "undefined") {
      mixpanel.track("Categories selected", {
        "Categories": categoriesSelected
      });
    }
    setTimeout(function(){
      if (typeof mixpanel != "undefined") {
        mixpanel.track("Sub Category viewed", {
          "Result count": $("#list").find(".item").filter(function(){
            var $this = $(this);
            return $this.css('display') != "none";
          }).length
        });
      }
    }, 500);

}
function callback_filters_inout(res){
  var _resultcb = res;
    var objName='.filterinout.loadajax-item';
    $(objName).not(':first').remove(); //remove all but firstone to execute listener&loop only in one dom
    var _elem = $(objName);
    jQuery.each( _resultcb, function( i, val ) {
      var tmpobj=_elem.clone(true).appendTo(_elem.parent());
      //tmpobj.find('.ckinout').attr("name",val.label);
      tmpobj.find('.ckinout').attr("name","ck_inout");
      tmpobj.find('.ckinout').attr("id","ckinout_"+val.value);
      tmpobj.find('.ckinout').attr("data-value",val.value);
      tmpobj.prop('checked', false);
      tmpobj.find('.lb_inout').attr("for", "ckinout_"+val.value);  
      tmpobj.find('.lb_inout').html(val.label);      

      
      tmpobj.removeClass("loadajax-item");
      tmpobj.css('opacity',0);
      tmpobj.delay(i*50).animate({opacity: 1},600);
    });
    $(objName).remove();
  if ((plg.PLG_getCookie("tlcpromo_in") == '0' || plg.PLG_getCookie("tlcpromo_in") == '')  && (plg.PLG_getCookie("tlcpromo_out") == '0' || plg.PLG_getCookie("tlcpromo_out") == '' )){
    $('.js-inout[data-value=1]').prop('checked', true);
    $('.js-inout[data-value=2]').prop('checked', true);
  }else{  
    if (plg.PLG_getCookie("tlcpromo_in") == '1' || plg.PLG_getCookie("tlcpromo_in") == ''){
      $('.js-inout[data-value=1]').prop('checked', true);
    }
    if (plg.PLG_getCookie("tlcpromo_out") == '1' || plg.PLG_getCookie("tlcpromo_out") == ''){
      $('.js-inout[data-value=2]').prop('checked', true);
    }
  }  
}
function callback_filters_categories(res){
  var _resultcb = res; 
  var _categories_sel = plg.PLG_getCookie("tlcpromo_categories").split(',');
    var objName='.filtercategories.loadajax-item';
    $(objName).not(':first').remove(); //remove all but firstone to execute listener&loop only in one dom
    var _elem = $(objName);
    jQuery.each( _resultcb, function( i, val ) {
      var tmpobj=_elem.clone(true).appendTo(_elem.parent());
      //tmpobj.find('.ckcategories').attr("name",val.title_parse);
      tmpobj.find('.ckcategories').attr("name","ck_categories");
      tmpobj.find('.ckcategories').attr("id", "ckcategory_"+val.category_id);
      tmpobj.find('.ckcategories').attr("data-value",val.category_id);
      tmpobj.find('.ckcategories').attr("data-in",val.in);
      tmpobj.find('.ckcategories').attr("data-out",val.out);
      tmpobj.find('.ckcategories').attr("data-subcategories",val.subcategories);
      if (jQuery.inArray( val.category_id.toString(), _categories_sel ) >= 0){ 
        tmpobj.find('.ckcategories').addClass('active');
        tmpobj.find('.ckcategories').prop( "checked", true );
      }  
      tmpobj.find('.lb_categories').attr("for", "ckcategory_"+val.category_id);
      tmpobj.find('.lb_categories').html(val.title);      

      tmpobj.removeClass("loadajax-item");
      tmpobj.css('opacity',0);
      tmpobj.delay(i*50).animate({opacity: 1},600);
    });
    $(objName).remove();
}
function callback_filters_subcategories(res){
  var _resultcb = res;
  var _categories_sel = plg.PLG_getCookie("tlcpromo_categories").split(',');
    var objName='.filtersubcategories.loadajax-item';
    $(objName).not(':first').remove(); //remove all but firstone to execute listener&loop only in one dom
    var _elem = $(objName);
    jQuery.each( _resultcb, function( i, val ) {
      var tmpobj=_elem.clone(true).appendTo(_elem.parent());
      //tmpobj.find('.cksubcategories').attr("name",val.title_parse);
      tmpobj.find('.cksubcategories').attr("name","ck_subcategories");
      tmpobj.find('.cksubcategories').attr("id", "cksubcategories_"+val.subcategory_id);
      tmpobj.find('.cksubcategories').attr("data-value",val.subcategory_id);
      tmpobj.find('.cksubcategories').attr("data-category",val.parent_id);
      tmpobj.find('.cksubcategories').attr("data-in",val.in);
      tmpobj.find('.cksubcategories').attr("data-out",val.out);
      tmpobj.find('.cksubcategories').attr("data-regions",val.regions_parse);

      
      if (jQuery.inArray( val.parent_id.toString(), _categories_sel ) >= 0){ 
        tmpobj.find('.cksubcategories').addClass('active');
        tmpobj.find('.cksubcategories').prop( "checked", true );
      }else{
        tmpobj.find('.cksubcategories').addClass('inactive');
        tmpobj.find('.cksubcategories').prop( "checked", false );
      }  
      tmpobj.find('.lb_subcategories').attr("for", "cksubcategories_"+val.subcategory_id);
      tmpobj.find('.lb_subcategories').html(val.title);      

      tmpobj.removeClass("loadajax-item");
      tmpobj.css('opacity',0);
      tmpobj.delay(i*50).animate({opacity: 1},600);
    });
    $(objName).remove();
}
function callback_filters_provinces(res){
  var _resultcb = res;
  var _categories_sel = plg.PLG_getCookie("tlcpromo_categories").split(',');
    var objName='.filterprovinces.loadajax-item';
    $(objName).not(':first').remove(); //remove all but firstone to execute listener&loop only in one dom
    var _elem = $(objName);
    jQuery.each( _resultcb, function( i, val ) {
      var tmpobj=_elem.clone(true).appendTo(_elem.parent());
      //tmpobj.find('.ckprovinces').attr("name",val.province_parse);
      tmpobj.find('.ckprovinces').attr("name","ck_regions");
      tmpobj.find('.ckprovinces').attr("id", "ckregions_"+val.province_parse);
      tmpobj.find('.ckprovinces').attr("data-value",val.province_parse);

      tmpobj.find('.lb_provinces').attr("for", "ckregions_"+val.province_parse);
      tmpobj.find('.lb_provinces').html(val.province);      

      tmpobj.removeClass("loadajax-item");
      tmpobj.css('opacity',0);
      tmpobj.delay(i*50).animate({opacity: 1},600);

      var _checked = false;
      $(".js-subcategories").each(function( index ) { 
        if ($(this).prop('checked')){
          var _regions = $(this).attr('data-regions').split(',');
          //console.log($(this).attr('data-value')+'>>'+_regions+'>>'+val.province_parse);
          if (jQuery.inArray( val.province_parse, _regions ) >= 0){
            _checked = true;
            //console.log('siii'+val.province_parse);
          }  
        }  
      });  
      if (_checked){
        tmpobj.find('.ckprovinces').addClass('active');
        tmpobj.find('.ckprovinces').prop( "checked", true );
      }else{
        tmpobj.find('.ckprovinces').addClass('inactive');
        tmpobj.find('.ckprovinces').prop( "checked", false );
      }

      
    });
    $(objName).remove();
}
function callback_filters_sectionsubcategories(res){
  var _resultcb = res;
    var objName='.card-single.loadajax-item';
    $(objName).not(':first').remove(); //remove all but firstone to execute listener&loop only in one dom
    var _elem = $(objName);
    
    var _order1;
    var _order2;

    jQuery.each( _resultcb, function( i, val ) {

      if (i % 2 == 0){
        _order1 = 1;
        _order2 = 2;
      }else{
        _order1 = 2;
        _order2 = 1;
      }

      var tmpobj=_elem.clone(true).appendTo(_elem.parent());      
      tmpobj.attr('data-category', val.parent_id);
      tmpobj.attr('data-subcategory', val.subcategory_id);
      tmpobj.attr('data-in', val.in);
      tmpobj.attr('data-out', val.out);
      tmpobj.attr('data-regions', val.regions_parse);

      tmpobj.find(".con-info").find('.imagen').css("background-image","url('"+val.image+"')");
      tmpobj.find(".con-info").find('.capa').css("background-color",val.bgcolor);
      tmpobj.find(".con-info").find('.capa').find('.logo').attr('src',val.image_network);
     
      tmpobj.find(".text-hover").find("p").html(val.description);

      tmpobj.find(".js-select").attr('data-evento', tmpobj.find(".js-select").attr('data-evento')+' '+val.title);
      
      tmpobj.removeClass("loadajax-item");
      tmpobj.css('opacity',0);
      tmpobj.delay(i*50).animate({opacity: 1},400);
      tmpobj.find(".con-info").delay(i*50).animate({opacity: 1},400);
    });
    $('#js-btsearch').delay(1000).animate({opacity: 1},400);
    $(objName).remove();
  //setTimeout(apply_filters(), 5000);
  //apply_filters();
}
function initMap() {
  map = new google.maps.Map(document.getElementById('map'), {
          zoom: 5
    });
  const options = {
    fields: ["formatted_address", "geometry", "name"],
    origin: map.getCenter()				
  };

  const input = document.getElementById("pac-input");

  const autocomplete = new google.maps.places.Autocomplete(input, options);	

  autocomplete.addListener("place_changed", function() {
      
      const place = autocomplete.getPlace();

      if (!place.geometry || !place.geometry.location) {
        // window.alert("No details available for input: '" + place.name + "'");
        $(".cont-slider").addClass("disabled");
        return;
      }

      var lat_input = place.geometry.location.lat();
      var lng_input = place.geometry.location.lng();
      // If the place has a geometry, then present it on a map.
      $('#lat').val(lat_input);
      $('#lng').val(lng_input);
      
      $(".cont-slider").removeClass("disabled");
      
      searchRadius();
      
  });
}
function searchRadius(){
      if ($('#pac-input').val() != ''){
        $('.grid-items').fadeOut();
        var _ckinout = [];
        var _ckcategories = [];
        var _cksubcategories = [];

        $( ".js-inout" ).each(function( index ) {
          if ($(this).prop('checked')){
            _ckinout.push(parseInt($(this).attr('data-value')));
          }
        });

        $( ".js-categories" ).each(function( index ) {
          if ($(this).prop('checked')){
            _ckcategories.push($(this).attr('data-value'));
          }
        });

        $( ".js-subcategories" ).each(function( index ) {
          if ($(this).prop('checked')){
            _cksubcategories.push($(this).attr('data-value'));
          }
        });



        $('#txt-results').hide();
        $('#load').show();
        var _datos = $('#fRadius').serialize()+'&ckinout='+_ckinout.join(',')+'&ckcategories='+_ckcategories.join(',')+'&cksubcategories='+_cksubcategories.join(',');
        $.ajax({
          url: "ajax/ajax-radius-subcategories.php",
          type: "POST",
          dataType: "json",
          data: _datos,
          success: function (response) {            
              //console.log(response);
              if (response.result == 'OK'){
                if (response.results > 0 ){
                  $('#txt-results span').html($('#results1').val()+response.results+$('#results2').val());
                }
                else{
                  $('#txt-results span').html($('#noresults').val());
                }	
                
                var _subcategories_ids = response.subcategories_ids.split(',');

                //reordenarItems();
                $('.js-subcategory').hide();

                $.each(_subcategories_ids, function(index, value) {
                    $('.js-subcategory[data-subcategory="'+parseInt(value)+'"]').show();  
                }); 
                
                
      
                $('.grid-items').fadeIn();
                $('#txt-results').fadeIn();
                $('#load').fadeOut();
              }else{
                plg.PLG_showModalAlerta($('#txtups').val(), $('#txtgeneric_error').val());
                $('.grid-items').fadeOut();
                $('#txt-results').fadeIn();
                $('#load').fadeOut();
              }  
          },
          error: function (xhr, status) {
            plg.PLG_showModalAlerta($('#txtups').val(), $('#txtgeneric_error').val());
            $('.grid-items').fadeOut();
            $('#txt-results').fadeOut();
            $('#load').fadeOut();
          },
          complete: function (xhr, status) {
          }
        });
      }
      else{
        plg.PLG_showModalAlerta($('#txtups').val(), $('#txtgeneric_error').val());
        $('#row-listado').show();
        $('#txt-results').show();
        $('#load').fadeOut();
      }	
}